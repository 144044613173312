
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  watch,
  onUpdated,
  onMounted,
} from "vue";
import {
  FileImageOutlined,
  PlayCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons-vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import { IVideo, IImage } from "@/_modules/types";
import useStaffRepositories from "@/_composables/useStaffRepositories";
import { format } from 'date-fns'
import { th } from 'date-fns/locale'
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import addYears from "date-fns/addYears";

export default defineComponent({
  components: {
    FileImageOutlined,
    PlayCircleOutlined,
    VideoPlayer,
  },
  props: {
    staffId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const slick = ref();
    const now = format(new Date(), 'dd MMMM', { locale: th })
    const yearTh = new Date().getFullYear() + 543
      const nowEn = format(new Date(), "dd MMMM");
    const yearEn = new Date().getFullYear();
    const updatedDate = ref(`${now} ${yearTh}`)
        const updatedDateEn = ref(`${nowEn} ${yearEn}`);

    const store = useStore();
    const { t } = useI18n({
      useScope: "global",
    });
    const state = reactive({
      images: [] as IImage[],
      video: null as IVideo | null,
      videos: [] as IVideo[],
      videoPage: 1,
      videoSize: 3,
      videoLastPage: 1,
      loadingMoreVideo: false,
      lastPage: 1,
      isFullVideo: false,
      imageSize: 20,
      imagePage: 1,
      lastupdateVideo:{
        th:"",
        en:""
      },
      lastupdateImage:{
        th:"",
        en:""
      }
    });

    const { getImages, getVideos } = useStaffRepositories();
    const { staffId } = toRefs(props);
    onMounted(async () => {
      try {
        await onFetchVideos({ size: 3, page: state.videoPage });
        await onFetchImages({ size: state.imageSize, page: state.imagePage });
      } catch (error) {
        // console.log("Tab6 fetch :: catch ", state, error);
      } finally {
        // console.log("Tab6 state :: finally", state);
      }
    });
    const fetchImage = async (params: any) => {
      if (!staffId) {
        return;
      }
      const result = await getImages(staffId.value, params);
      if (result) {
        const { data, currentPage: page, lastPage, perPage,last_updated } = result.medias;

        const formatDateTH = format(addYears(new Date(last_updated), 543), 'dd MMMM yyyy', { locale: th })
        const formatDateEN = format(new Date(last_updated), 'dd MMMM yyyy')
        state.lastupdateImage = {
          th:formatDateTH,
          en:formatDateEN
        }
        console.log('result',typeof last_updated,format(new Date(last_updated), 'dd MMMM yyyy') );
        if (data) {
          state.images = [...state.images, ...data];
        }
      }
    };

    const fetchVideo = async (params: any) => {
      if (!staffId) {
        return;
      }
      const result = await getVideos(staffId.value, params);
      if (result) {
        const { data, currentPage, lastPage, perPage, total,last_updated } = result.medias;
        const formatDateTH = format(addYears(new Date(last_updated), 543), 'dd MMMM yyyy', { locale: th })
        const formatDateEN = format(new Date(last_updated), 'dd MMMM yyyy')
        state.lastupdateVideo = {
          th:formatDateTH,
          en:formatDateEN
        }
        if (data) {
          state.video = data[0];
          state.videos = [...data];
          state.videoLastPage = lastPage;
          if (total === 3) {
            state.isFullVideo = true;
          }
        }
      }
    };

    const onFetchVideos = async (param: any) => {
      await fetchVideo(param);
    };

    const onFetchImages = async (param: any) => {
      await fetchImage(param);
    };

    const onClickVideoInPlaylist = (video:any) => {
      state.video = video
      // state.vid eo.poster = `https://picsum.photos/id/20/200/100`
    };

    const onNextVideo = async () => {
      if (state.lastPage === state.videoPage) {
        state.lastPage += 1;
        state.loadingMoreVideo = true;
        await onFetchVideos({ size: 3, page: state.lastPage });

        setTimeout(() => {
          state.videoPage += 1;
          state.loadingMoreVideo = false;
          slick.value.next();
        }, 500);
      } else {
        slick.value.next();
        state.videoPage += 1;
      }
    };

    onUpdated(() => {
      // console.log("onUpdated onUpdated", {
      //   lastPage: state.lastPage,
      //   videoPage: state.videoPage,
      //   isFullVideo: state.isFullVideo,
      // });
    });
    const onPreviousVideo = async() => {
      state.lastPage -= 1;
      await onFetchVideos({ size: 3, page: state.lastPage });
      if (state.videoPage > 1) {
        state.videoPage -= 1;
        slick.value.prev();
      }
    };

    return {
      t,
      store,
      ...toRefs(state),
      updatedDate,
      updatedDateEn,
      onClickVideoInPlaylist,
      onNextVideo,
      slick,
      onPreviousVideo,
    };
  },
});
