
import { defineComponent, reactive, toRefs, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { useRequest } from "vue-request";
import SatIcon from "@/components/SatIcon.vue";
import SatCardCarousel from "@/components/carousel/SatCardCarousel.vue";
import { CalendarOutlined, FileProtectOutlined } from "@ant-design/icons-vue";
import SatBreadcrumb from "@/components/SatBreadcrumb.vue";
import useStaffRepositories from "@/_composables/useStaffRepositories";
import { athleteService } from "@/_services";
import Tab1 from '@/views/staff/Tab1.vue'
import Tab2 from '@/views/staff/Tab2.vue'
import Tab3 from '@/views/staff/Tab3.vue'
import Tab4 from '@/views/staff/Tab4.vue'
import Tab5 from '@/views/staff/Tab5.vue'
import {
  Athlete,
  BaseResponse,
  BreadcrumbRoute,
  IAthlete,
  IStaff,
} from "@/_modules/types";
import { useStore } from "vuex";
import addYears from "date-fns/addYears";

export default defineComponent({
  name: "Staff",
  components: {
    Tab1,
    Tab2,
    Tab3,
    Tab4,
    Tab5,
    SatIcon,
    FileProtectOutlined,
    SatCardCarousel,
    SatBreadcrumb,
    CalendarOutlined,
  },
  setup() {
    const { getStaff } = useStaffRepositories();
    const { t } = useI18n({
      useScope: "global",
    });
    const store = useStore();
    const route = useRoute();
    watch(
      () => route.params.id,
      async (newId) => {
        if (newId) {
          const result = await getStaff(+newId);
          if (result) {
            state.staff = { ...result };
            routes.value[1].breadcrumbName = state.staff.name.th;
            state.firstTab.info.forEach((e, idx, arr) => {
              const { key } = e;
              // console.log("firstTab :: ", e, idx, arr);
              switch (key) {
                case "age":
                  arr[idx].value = {
                    th:`${result.age} ปี`,
                    en:`${result.age} years`
                  }
                  break;
                case "dob":
                  arr[idx].value = {
                    th: format(addYears(new Date(result.birthday), 543), "dd MMMM yyyy", {
                locale: th,
              }),
                    en: format(new Date(result.birthday), "dd MMMM yyyy"),
                  };

                  break;
                case "nationality":
                  arr[idx].value = {
                    th:result.nationallity.th,
                    en:result.nationallity.en
                  }
                  break;
                case "height":
                  arr[idx].value = {
                    th:result.height ? `${result.height} ซม.` : "-",
                    en:result.height ? `${result.height} cm.` : "-"
                  }
                  break;
                case "weight":
                  arr[idx].value = {
                    th:result.weight ? `${result.weight} กก.` : "-",
                    en:result.weight ? `${result.weight} kg.` : "-"
                  }
                  break;
              }
            });
          }
        }
        // await fetchAthlete(+newId)
      },
      { immediate: true }
    );
    const state = reactive({
      loading: true,
      staff: {} as IStaff,

      tabs: {
        th: [
          { type: "ประวัติ", title: "ข้อมูลส่วนตัว" },
          { type: "ประวัติ", title: "สโมสร/ทีมชาติ" },
          { type: "ประวัติ", title: "รางวัลส่วนตัว" },
          { type: "ประวัติ", title: "การโดนลงโทษ" },
          { type: "คลัง", title: "ภาพและวิดีโอ" },
        ],
        en: [
          { type: "Record", title: "Personal Information" },
          { type: "Record", title: "Team/Club" },
          { type: "Record", title: "Hall of Fame" },
          { type: "Record", title: "Punishment" },
          { type: "", title: "Images & Videos" },
        ],
      },
      firstTab: {
        info: [
          {
            key: "dob",
            title: {
              en:"Date of Birth",
              th:"เกิดวันที่"
            },
            icon: "CalendarOutlined",
            props: { class: "fs-5 me-2" },
            value: {
              en:"-",
              th:"-"
            }
          },
          {
            key: "age",
            title: {
              en:"Age",
              th:"อายุ"
            },
            icon: "SatIcon",
            props: { name: "manCircle", cssClass: "me-2 fs-5" },
            value: {
              en:"-",
              th:"-"
            }
          },
          {
            key: "nationality",
            title: {
              en:"Nationality",
              th:"สัญชาติ"
            },
            icon: "SatIcon",
            props: { name: "idCard", cssClass: "me-2 fs-5" },
            value: {
              en:"-",
              th:"-"
            }
          },
          {
            key: "height",
            title: {
              en:"Height",
              th:"ส่วนสูง"
            },
            icon: "SatIcon",
            props: { name: "heightMan", cssClass: "me-2 fs-5" },
            value: {
              en:"-",
              th:"-"
            }
          },
          {
            key: "weight",
            title: {
              en:"Weight",
              th:"น้ำหนัก"
            },
            icon: "SatIcon",
            props: { name: "weightMachine", cssClass: "me-2 fs-5" },
            value: {
              en:"-",
              th:"-"
            }
          },
        ],
        content: "",
      },
    });
    const routes = ref<BreadcrumbRoute[]>([
      {
        path: "staffs",
        breadcrumbName: t("label.overview.staffs"),
      },
      {
        breadcrumbName: "-",
      },
    ]);
    return {
      t,
      store,
      ...toRefs(state),
      routes,
      activeKey: ref(0),
    };
  },
});
