
import { defineComponent, reactive, toRefs, ref, watch, computed } from "vue";
import { ClockCircleOutlined } from "@ant-design/icons-vue";
import useStaffRepositories from "@/_composables/useStaffRepositories";
import { LayoutHeader } from "ant-design-vue";
import SatIcon from "@/components/SatIcon.vue";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { useI18n } from "vue-i18n";
import addYears from "date-fns/addYears";
import { useStore } from "vuex";
export default defineComponent({
  components: {
    SatIcon,
  },
  props: {
    staffId: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const store = useStore();
    const { t } = useI18n({
      useScope: "global",
    });
    const { getTeamHistoryNation, getTeamHistoryClub } = useStaffRepositories();
    const state = reactive({
      dataNation: [] as any,
      dataIndexNation: 0,
      previousNationTeam: "",
      dataClub: [] as any,
      dataIndexClub: 0,
      previousClubTeam: "",
      currentNationPage: 1,
      lastNationPage: 1,
      currentClubPage: 1,
      lastClubPage: 1,
    });
    const { staffId } = toRefs(props);

    const formatDate = (date: any, language: string) => {
      if (language === "th") {
        return format(addYears(new Date(date),543), "dd/MM/yyyy");
      } else {
        return format(new Date(date), "dd/MM/yyyy");
      }
    };
    const fetchTeamHistoryNation = async (params: string | number) => {
      if (!staffId) {
        return;
      }
      const nation = await getTeamHistoryNation(staffId.value, {
        page: +params,
        size: 5,
      });
      state.dataNation = nation?.history?.data;
      state.currentNationPage = nation?.history?.currentPage;
      state.lastNationPage = nation?.history?.lastPage;
    };

    const fetchTeamHistoryClub = async (params: string | number) => {
      if (!staffId) {
        return;
      }
      const club = await getTeamHistoryClub(staffId.value, {
        page: +params,
        size: 5,
      });
      state.dataClub = club?.history?.data;
      state.currentClubPage = club?.history?.currentPage;
      state.lastClubPage = club?.history?.lastPage;
    };

    const loadMoreHistory = async () => {
      state.currentNationPage = state.currentNationPage + 1;
      state.currentClubPage = state.currentClubPage + 1;
      if (
        state.currentNationPage <= state.lastNationPage ||
        state.currentClubPage <= state.lastClubPage
      ) {
        fetchTeamHistoryNation(state.currentNationPage);
        fetchTeamHistoryClub(state.currentNationPage);
      }
    };

    watch(
      () => [staffId],
      () => {
        if (staffId.value) {
          fetchTeamHistoryNation("1");
          fetchTeamHistoryClub("1");
        }
      },
      { immediate: true }
    );

    return {
      t,
      store,
      ...toRefs(state),
      loadMoreHistory,
      formatDate,
    };
  },
});
