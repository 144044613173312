
import { defineComponent, reactive, toRefs, ref, watch, onMounted } from "vue";
import useStaffRepositories from "@/_composables/useStaffRepositories";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
export default defineComponent({
  props: {
    staffId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
      const store = useStore();
           const { t } = useI18n({
      useScope: "global",
    });
    const { staffId } = props
    const { getPenaltiesCurrent, getPenalties } = useStaffRepositories();
    const state = reactive({
      pageSizeOptions: ["10", "20", "50", "100"],
      columns: [
        {
          title: "ชื่อบทลงโทษ",
          dataIndex: "name",
          key: "name",
          align: "center",
        },
        {
          title: "รายการแข่งขัน",
          dataIndex: "competition",
          key: "competition",
        },
        {
          title: "รอบการแข่งขัน",
          dataIndex: "match",
          key: "match",
        },
        {
          title: "วันที่โดนบทลงโทษ",
          dataIndex: "date",
          key: "date",
        },
        {
          title: "ระยะเวลาที่โดนลงโทษ",
          dataIndex: "duration",
          key: "duration",
        },
        {
          title: "การยื่นอุทธรณ์",
          dataIndex: "appeal",
          key: "appeal",
        },
      ],
      columnsEN: [
        {
          title: "Penalty Name",
          dataIndex: "name",
          key: "name",
          align: "center",
        },
        {
          title: "Competition Name",
          dataIndex: "competition",
          key: "competition",
        },
        {
          title: "Match",
          dataIndex: "match",
          key: "match",
        },
        {
          title: "Date of Penalty",
          dataIndex: "date",
          key: "date",
        },
        {
          title: "Punishment Duration",
          dataIndex: "duration",
          key: "duration",
        },
        {
          title: "Appealing",
          dataIndex: "appeal",
          key: "appeal",
        },
      ],
      dataSourcePenalies: {},
      dataSourcePenaliesCurrent: {},
      pagePenalies: {
        currentPage: 1,
        lastPage: 1,
        perPage: 10,
        total: 1,
      },
      pagePenaliesCurrent: {
        currentPage: 1,
        lastPage: 1,
        perPage: 10,
        total: 1,
      },
      loading: true,
      currentPagePenaltiesCurrent: 1,
      currentPagePenalties: 1,
    });

    onMounted(async () =>{
      try{

 await fetchPenaliesCurrent({
          size: state.pageSizeOptions[0],
          page: state.currentPagePenaltiesCurrent
        });
         await fetchPenalies({
          size: state.pageSizeOptions[0],
          page: state.currentPagePenalties,
        });
      }catch (error) {
      } finally {
        state.loading = false;
      }
    })

    const fetchPenaliesCurrent = async (params:any) => {
      if (!staffId) {
        return;
      }
      const result = await getPenaltiesCurrent(staffId, params);

      const { data,...others } = result.penalty;

      const penalty = data.map((ele: any, index: number) => {
        return {
          key: "" + (index + 1),
          name: ele[store.state.currentLanguage]["name"],
          competition: ele[store.state.currentLanguage]["competition"],
          match: ele[store.state.currentLanguage]["match"],
          appeal: ele[store.state.currentLanguage]["appeal"],
          duration: ele.duration,
          date: ele.date,
        };
      });

      state.dataSourcePenaliesCurrent = {
        ...others,
        data:penalty
      };
    };

    const fetchPenalies = async (params:any) => {
      if (!staffId) {
        return;
      }
      const result = await getPenalties(staffId, params);

      const { data, ...others } = result.penalty;

      const penalty = data.map((ele: any, index: number) => {
        return {
          key: "" + (index + 1),
          name: ele[store.state.currentLanguage]["name"],
          competition: ele[store.state.currentLanguage]["competition"],
          match: ele[store.state.currentLanguage]["match"],
          appeal: ele[store.state.currentLanguage]["appeal"],
          duration: ele.duration,
          date: ele.date,
        };
      });

      state.dataSourcePenalies = {
        ...others,
        data:penalty
      };

    };
const onPageChangePenaltiesCurrent = (page:any, pageSize:any) => {
      state.currentPagePenaltiesCurrent = page;
      fetchPenaliesCurrent({ page, size: pageSize });
    };
    const onShowSizeChangePenaltiesCurrent = (current:any, pageSize:any) => {
      state.currentPagePenaltiesCurrent = current;
      fetchPenaliesCurrent({ page: current, size: pageSize });
    };
    const onPageChangePenalties = (page:any, pageSize:any) => {
      state.currentPagePenalties = page;
      fetchPenalies({ page, size: pageSize });
    };
    const onShowSizeChangePenalties = (current:any, pageSize:any) => {
      state.currentPagePenalties = current;
      fetchPenalies({ page: current, size: pageSize });
    };

 watch(
      () => store.state.currentLanguage,
      (newValue) => {
        fetchPenaliesCurrent({ page: state.currentPagePenaltiesCurrent, size: state.pageSizeOptions[0] });
        fetchPenalies({ page: state.currentPagePenalties, size: state.pageSizeOptions[0] });

      }
    );
    return {
      t,
      store,
      ...toRefs(state),
      onShowSizeChangePenalties,
      onPageChangePenalties,
      onShowSizeChangePenaltiesCurrent,
      onPageChangePenaltiesCurrent
    };
  },
});
